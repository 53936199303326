<!-- 需求阶段 -->
<template>
    <div v-loading="loading" @click="sizechange" ref="MyChartDom" class="my_tasks">
        <div class="my_tasks_title">需求阶段<span style="margin-left: 5px;">2/6</span></div>
        <div class="my_tasks_item_wrap" :style="{ left: num + 'px' }">
            <div class="item-row" v-for="(item, i) in list" :key="i">
                <div class="item-box" :style="{ border: '1px solid', borderColor: i === 1 ? 'red' : '#ccc' }">
                    <div class="children" v-for="val in item.children" :key="val">
                        <div class="content" :style="{ color: parseInt(val.value) === 0 ? '#fff' : val.color }">
                            {{ val.name }}
                            <span @click="goUrl(val.url, val.value)"
                                :style="{ color: parseInt(val.value) === 0 ? '#fff' : val.color }">{{ val.value
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { demandStage } from "@/api/ChartsDataRequest";

export default {
    name: "terminated_customer",
    props: [],
    emits: ["closeChart"],
    data() {
        return {
            num: 0,
            loading: true,
            isShow: '',
            w: '100%',
            list: [
                {
                    children: [
                        {
                            name: '需求未发布：',
                            url: '/customerManage/planRequirements?jump_type=1',
                            value: '',
                        },
                        {
                            name: '需求发布未承接：',
                            url: '/customerManage/reviewedWorkstations?jump_type=1',
                            value: '',
                        },
                        {
                            name: '需求发布已承接：',
                            url: '/customerManage/reviewedWorkstations?jump_type=2',
                            value: '',
                        }

                    ]
                },
                {
                    children: [
                        {
                            name: '超15天未承接：',
                            url: '/customerManage/reviewedWorkstations?jump_type=6',
                            value: '',
                            color: '#FF0000',
                        },
                        {
                            name: '超15天未承接预警(3天)：',
                            url: '/customerManage/reviewedWorkstations?jump_type=7',
                            value: '',
                            color: '#f9b707',
                        },

                    ]
                },
            ],
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },

        // 图表创建
        createChart() {
            this.loading = false;
            demandStage().then((res) => {
                if (res.data.code == 200) {
                    this.list.forEach(item => {
                        item.children.forEach(v => {
                            if(v.name === '需求未发布：'){
                                v.value = res.data.data.demand_no_publish_cont
                            }else if (v.name === '需求发布未承接：') {
                                v.value = res.data.data.demand_audit_no_assign_cont
                            } else if (v.name === '需求发布已承接：') {
                                v.value = res.data.data.demand_audit_assign_cont
                            } else if (v.name === '超15天未承接：') {
                                v.value = res.data.data.demand_audit_no_assign_cont_15day
                            } else if (v.name === '超15天未承接预警(3天)：') {
                                v.value = res.data.data.demand_audit_no_assign_cont_warn
                            }
                        })
                    })

                } else {
                    console.log("Robot Data request fail");
                }
                this.loading = false;
            });
        },
        // 跳转
        goUrl(row, value) {
            if (parseInt(value) !== 0) window.$wujie.props.method.propsMethod(row)
        },
        sizechange() {
            let obj = document.querySelector('.my_tasks');
            let height = obj.offsetHeight;
            console.log(height, 1111);
        }
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
    color: #fff;
    font-size: 18px;
    padding-top: 8px;
}
.my_tasks .my_tasks_item_wrap .item-row{
    // height: 0;
}

.my_tasks {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .my_tasks_title {
        font-size: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 68px;
        background-color: rgba(255, 255, 255, 0.08);
        color: #fff;
    }

    .my_tasks_item_wrap {
        width: v-bind(w);
        // height: 80%;
        height: 38%;
        text-align: left;
        align-items: center;
        transition: all 0.1s ease-in-out;

        .item-row {
            width: 360px;
            // height: 100%;
            margin-left: 20px;

            .item-box {
                width: 100%;
                // height: 80%;
                min-height:180px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #ccc;
                border-radius: 15px;
                font-size: 1.4rem;
                color: #fff;
                margin-top: 10px;

                .children {
                    display: block;
                    .content {
                        margin-top: 20px;
                        span {
                            color: #f9b707;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>
