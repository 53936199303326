<!-- 运维阶段 -->
<template>
    <div v-loading="loading" @click="resizedChange" ref="MyChartDom" class="my_tasks">
        <div class="my_tasks_title">运维阶段<span style="margin-left: 5px;">6/6</span></div>
        <div class="my_tasks_item_wrap" :style="{ left: num + 'px' }">
            <div class="item-row" v-for="(item, i) in list" :key="i">
                <div class="item-box" :style="{ border: '1px solid', borderColor: i === 1 ? 'red' : '#ccc' }">
                    <div class="children" v-for="val in item.children" :key="val">
                        <div class="content" :style="{ color: parseInt(val.value) === 0 ? '#fff' : val.color }">
                            {{ val.name }}
                            <span @click="goUrl(val.url, val.value)"
                                :style="{ color: parseInt(val.value) === 0 ? '#fff' : val.color }">{{ val.value }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { maintainStage } from "@/api/ChartsDataRequest";

export default {
    name: "terminated_customer",
    props: [],
    emits: ["closeChart"],
    data() {
        return {
            num: 0,
            loading: true,
            isShow: '',
            w: '100%',
            list: [
                {
                    children: [
                        {
                            name: '稳定运行收费：',
                            url: '/customerManage/stationManagement?work_status=11',
                            value: '',
                        },
                        {
                            name: '非稳定运行收费：',
                            url: '/customerManage/stationManagement?work_status=12',
                            value: '',
                        },
                        {
                            name: '正常暂停：',
                            url: '/customerManage/stationManagement?work_status=13',
                            value: '',
                        },
                        {
                            name: '总计正常已退机：',
                            url: '/customerManage/stationManagement?work_status=15',
                            value: '',
                        },
                        {
                            name: '30天内正常已退机：',
                            url: '/customerManage/stationManagement?jump_type=11',
                            value: '10',
                        },
                        {
                            name: '总计异常已退机：',
                            url: '/customerManage/stationManagement?work_status=16',
                            value: '',
                        },
                        {
                            name: '30天内异常已退机：',
                            url: '/customerManage/stationManagement?jump_type=12',
                            value: '10',
                        },
                    ]
                },
                {
                    children: [
                        // 超期
                        {
                            name: '暂停超期(3天)：',
                            url: '/customerManage/stationManagement?jump_type=8',
                            value: '',
                            color: '#FF0000',
                        },
                        // 预警
                        {
                            name: '暂停超期预警(1天)：',
                            url: '/customerManage/stationManagement?jump_type=19',
                            value: '',
                            color: '#f9b707',
                        }
                    ]
                }
            ],
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },

        // 图表创建
        createChart() {
            this.loading = false;
            maintainStage().then((res) => {
                if (res.data.code == 200) {
                    this.list.forEach(item => {
                        item.children.forEach(v => {
                            if (v.name === '稳定运行收费：') {
                                v.value = res.data.data.good_running_charge
                            } else if (v.name === '非稳定运行收费：') {
                                v.value = res.data.data.bad_running_charge
                            } else if (v.name === '正常暂停：') {
                                v.value = res.data.data.normal_stop
                            } else if (v.name === '总计正常已退机：') {
                                v.value = res.data.data.normal_back
                            } else if (v.name === '总计异常已退机：') {
                                v.value = res.data.data.question_back
                            } else if (v.name === '暂停超期(3天)：') {
                                v.value = res.data.data.stop_delay
                            } else if (v.name === '暂停超期预警(1天)：') {
                                v.value = res.data.data.stop_warn
                            } else if (v.name === '30天内正常已退机：') {
                                v.value = res.data.data.normal_back_30day
                            } else if (v.name === '30天内异常已退机：') {
                                v.value = res.data.data.question_back_30day
                            }
                        })
                    })

                } else {
                    console.log("Robot Data request fail");
                }
                this.loading = false;
            });
        },
        // 跳转
        goUrl(row, value) {
            if (parseInt(value) !== 0) window.$wujie.props.method.propsMethod(row)

        }
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
    color: #fff;
    font-size: 18px;
    padding-top: 8px;
}

.my_tasks {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .my_tasks_title {
        font-size: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 68px;
        background-color: rgba(255, 255, 255, 0.08);
        color: #fff;

    }

    .my_tasks_item_wrap {
        width: v-bind(w);
        // height: 80%;
        height: 42%;
        text-align: left;
        align-items: center;
        transition: all 0.1s ease-in-out;
        position: relative;

        .item-row {
            width: 360px;
            height: 100%;
            margin-left: 20px;

            .item-box {
                width: 100%;
                // height: 100%;
                min-height:376px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #ccc;
                border-radius: 15px;
                font-size: 1.4rem;
                color: #fff;
                margin-top: 10px;

                .children {
                    display: block;

                    .content {
                        margin-top: 20px;

                        span {
                            color: #f9b707;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>
